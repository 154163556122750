import { Air } from '@air/next-icons';
import Link from 'next/link';
import { memo, PropsWithChildren } from 'react';

export const AppErrorBackground = memo(({ children }: PropsWithChildren<object>) => (
  <main
    className="flex h-screen bg-cover bg-no-repeat"
    style={{
      backgroundImage: 'url(/assets/illustrations/blue-blurred-background.svg)',
    }}
  >
    <div
      className="absolute inset-0 bg-cover bg-no-repeat"
      style={{
        backgroundImage: 'url(/assets/illustrations/air-blurred.svg)',
      }}
    />
    <div className="z-1 mx-auto flex h-[calc(100vh-52px)] max-w-[500px] flex-1 flex-col items-center justify-center gap-4 p-8 text-center">
      <Link href="https://air.inc">
        <Air className="absolute left-8 top-10 w-[65px] text-blue-8" />
      </Link>
      {children}
    </div>
  </main>
));

AppErrorBackground.displayName = 'AppErrorBackground';
